import React from 'react';
import Tiles from './Tiles/Tiles';
import styled from 'styled-components';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto 170px;
  padding: 90px 60px 50px;
  display: flex;
  box-shadow: 0 0 121px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    border-radius: 0;
    margin: 0 auto;
    padding: 30px 30px 20px;
  }
`;

const featuresList = ({ data }) => {
  return (
    <Container>
      <Tiles data={data} />
    </Container>
  );
};

export default featuresList;
