import React from 'react';
import styled from 'styled-components';

import Icon0 from './img/0.png';
import Icon1 from './img/1.png';
import Icon2 from './img/2.png';
import Icon3 from './img/3.png';
import Icon4 from './img/4.png';
import Icon5 from './img/5.png';
import Icon6 from './img/6.png';
import Icon7 from './img/7.png';
import Icon8 from './img/8.png';
import Icon9 from './img/9.png';
import Icon10 from './img/10.png';
import Icon11 from './img/11.png';

const images = {
  0: Icon0,
  1: Icon1,
  2: Icon2,
  3: Icon3,
  4: Icon4,
  5: Icon5,
  6: Icon6,
  7: Icon7,
  8: Icon8,
  9: Icon9,
  10: Icon10,
  11: Icon11,
};

const ListItem = styled.li`
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 15px;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ImgWrap = styled.figure`
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.strong`
  display: block;
  color: #4f289a;
  font-family: 'Visby CF';
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const Text = styled.p`
  opacity: 0.8;
  color: #4f289a;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Manrope';
  line-height: 1.8;
`;

const handleDelay = (number) => {
  if(number === 1) {
    return 0;
  } else if(number === 2) {
    return 300;
  } else {
    return 600;
  }
};

const tile = ({ feature, nr }) => {
  return (
    <ListItem data-aos="fade-in" data-aos-delay={handleDelay(nr)}>
      <ImgWrap>
        <img loading="lazy" src={images[feature.index]} alt={feature.heading} />
      </ImgWrap>
      <Heading>{feature.heading}</Heading>
      <Text>{feature.text}</Text>
    </ListItem>
  );
};
export default tile;
