import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import PageHero from '../components/PageHero/PageHero';
import ContentRows from '../components/ContentRows/ContentRows';
import FeaturesList from '../components/FeaturesList/FeaturesList';
import Benefits from '../components/Benefits/Benefits';
import ParallaxBlock from '../components/ParallaxBlock/ParallaxBlock';
import Footer from '../components/Footer/Footer';
import Packages from '../components/Packages/Packages';

import ParallaxImg from '../assets/images/ParallaxImg.png';

import ContentRowJSON from '../data/content-rows.json';
import FeaturesJSON from '../data/features.json';
import SEO from "../components/seo";

const whatYouGet = () => {
  return (
    <Layout>
      <SEO title="Co otrzymasz" />
      <PageHero pageName="co-otrzymasz">
        <h1>
          Co <strong>otrzymasz?</strong>
        </h1>
      </PageHero>
      <ContentRows imgFirst data={ContentRowJSON[4]} classes="wide" />
      <FeaturesList data={FeaturesJSON} />
      <Packages />
      <Benefits bgColor={'#fff'} showButtonUs={true} />
      <ParallaxBlock background={ParallaxImg}>
        <ParallaxBlock.HeadingSm>
          Przejrzyste zasady współpracy
        </ParallaxBlock.HeadingSm>
        <ParallaxBlock.Paragraph>
          U nas nie ma ukrytych kosztów. Nie płacisz żadnej opłaty na start.
          Jedyna opłata, jaką pobieramy, to uzgodniona z Tobą prowizja od
          wygenerowanej sprzedaży.
        </ParallaxBlock.Paragraph>
      </ParallaxBlock>
      <Footer>
        <Footer.Heading>
          <strong>Skontaktuj się</strong> z nami
        </Footer.Heading>
        <Footer.Paragraph>
          Blogerze! Youtuberze! Influencerze! Czekamy aż do nas napiszesz lub
          zadzwonisz. Jak się do nas nie odezwiesz, to trudno będzie nam Ci
          pomóc!
        </Footer.Paragraph>
      </Footer>
    </Layout>
  );
};

export default whatYouGet;
