import React from 'react';
import styled from 'styled-components';
import Tile from './Tile/Tile';

const TilesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

let nr = 0;

const tiles = ({ data }) => (
  <TilesList>
    {data.map((feature, index) => {
      nr = nr < 3 ? nr : 0;
      nr++;
      return (
        <Tile nr={nr} key={feature.index} feature={feature} />
      )
    })}
  </TilesList>
);

export default tiles;
